import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

const Login = React.lazy(() => import('./pages/Auth/Login'))
const PrivateRoute = React.lazy(() => import('./components/PrivateRoute'))
const Layout = React.lazy(() => import('./components/containers/Layout'))

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login/>}/>
        <Route element={<PrivateRoute/>}>
          <Route path='/*' element={<Layout/>}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
