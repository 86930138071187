import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./AuthContext";
import store from "./redux/store";
import { setContext } from "@apollo/client/link/context";

const container = document.getElementById('root')
const root = createRoot(container!)

const httpLink = createHttpLink({
  uri: 'https://trihaka-graphql-lld6aeuava-as.a.run.app/',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token || ''
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <AuthProvider>
        <App/>
      </AuthProvider>
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
