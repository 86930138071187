import {
  DATA_LOADED,
  DATA_LOADING,
  SET_CURRENT,
  SET_CURRENT_2,
  SupportActionType,
  SupportState
} from "./types";

const initialState: SupportState = {
  current: null,
  current2: null,
  isLoading: false,
}

export default function(state = initialState, action: SupportActionType): SupportState {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case DATA_LOADED:
      return {
        ...state,
        isLoading: false
      }
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload
      }
    case SET_CURRENT_2:
      return {
        ...state,
        current2: action.payload
      }
    default:
      return state
  }
}