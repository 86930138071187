import { combineReducers } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";

import auth from './auth/reducers'
import support from './support/reducers'

import { AuthState } from "./auth/types";
import { SupportState } from "./support/types";

interface RootState {
  auth: AuthState,
  support: SupportState
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export default combineReducers({
  auth,
  support
})