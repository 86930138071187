export const LOGIN_SUCCESS  = 'LOGIN_SUCCESS'

export interface LoginSuccessType {
  type: typeof LOGIN_SUCCESS
  payload: boolean
}

export interface AuthState {
  isAuth: boolean
}

export type AuthActionType =
  | LoginSuccessType