export const DATA_LOADING = 'DATA_LOADING'
export const DATA_LOADED = 'DATA_LOADED'
export const SET_CURRENT = 'SET_CURRENT'
export const SET_CURRENT_2 = 'SET_CURRENT_2'

export interface PaginationType {
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  prevPage: null | number | undefined,
  nextPage: null | number | undefined,
}

export interface DataLoadingType {
  type: typeof DATA_LOADING
}

export interface DataLoadedType {
  type: typeof DATA_LOADED
}

export interface SetCurrentType {
  type: typeof SET_CURRENT
  payload: any
}

export interface SetCurrent2Type {
  type: typeof SET_CURRENT_2
  payload: any
}

export interface SupportState {
  current: any | null
  current2: any | null
  isLoading: boolean
}

export type SupportActionType =
  | DataLoadingType
  | DataLoadedType
  | SetCurrentType
  | SetCurrent2Type