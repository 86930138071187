import {
  AuthActionType,
  AuthState,
  LOGIN_SUCCESS
} from "./types";

const initialState: AuthState = {
  isAuth: false
}

export default function (state= initialState, action: AuthActionType): AuthState {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: action.payload
      }
    default:
      return state
  }
}